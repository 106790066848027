@import "~@o4c/css/mixins/elevations";

.o4c.card.trip-nav-card {
  @include elevation2();

  .main {
    i {
      color: var(--grey-dark);
    }
  }
  .trip-id {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 0;
  }
  .body-text {
    color: var(--dark);
  }
  &.active {
    border: 2px solid var(--primary);
    i.icon-arrow-right {
      color: var(--primary);
    }
  }
  .pill {
    margin: 0 0 8px 0;
  }
}
