// Load CSS Framework Foundation and Icon font
@import "~@o4c/css/foundation";
@import "~@o4c/css/core/fonts";
@import '~@o4c/css/core/typography';


// Load CSS Framework Components
@import "~@o4c/css/components/timelines";
@import "~@o4c/css/components/cards";
@import '~@o4c/css/components/buttons';
@import '~@o4c/css/components/pills';

// Load CSS Framwork Mixins
@import "~@o4c/css/mixins/elevations";

* {
  // Prevent blue touch callout backgrounds
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

textarea,
input {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--grey-lighter);
  color: var(--dark);
  margin: 0;
  padding: 0;
}

.o4c.button.outline {
  @include elevation1();
}