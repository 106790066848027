@import "~@o4c/css/mixins/elevations";
@import "~@o4c/css/mixins/typography";
@import "~@o4c/css/functions/lighten";

.metadata {
  .trip-id {
    color: var(--primary);
    line-height: 20px;
  }
}

.o4c.timeline .item .card.trip-card {
  @include elevation2();
  color: var(--on-metadata);
  border: 1px solid var(--grey-light);
  margin: 16px 0 16px 0;
  background-color: var(--metadata);
  .main {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    background-color: var(--metadata);
    &.middle {
      background-color: var(--metadata-center);
      color: var(--on-metadata-center);
      .label {
        &.light {
          color: var(--on-metadata-center-label);
        }
      }
    }
    .label {
      @include extraSmallText();
      display: block;
      text-transform: uppercase;
      color: var(--on-metadata);
      font-weight: bold;
      &.light {
        color: lighten(--on-metadata-center, 0.4);
      }
      &.right {
        text-align: right;
      }
    }
    h1 {
      margin: 8px 0 0 0;
    }
    h3 {
      font-weight: normal;
      margin: 8px 0 0 0;
    }
    p {
      margin: 4px 0 0 0;
    }
    &.flex-50 {
      display: flex;
      div {
        flex-grow: 1;
        flex-basis: 50%;
      }
    }
  }
  .divider {
    height: 1px;
    width: 100%;
    mix-blend-mode: normal;
  }
}
