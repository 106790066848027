@import "~@o4c/css/mixins/typography";

.full-screen-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
  background: var(--grey-lighter);
  span {
    @include captionText();
    width: 100%;
    flex: 0 1;
    display: block;
    margin: 0 0 8px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .spinner {
    flex: 0 1;
  }
}
