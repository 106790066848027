@import "~@o4c/css/mixins/elevations";
@import "~@o4c/css/mixins/typography";

.o4c.timeline .item .card.stop {
  @include elevation2();
  margin: 16px 0 0 0;

  .comment {
    margin: 16px 0 0 0;
  }

  .to-number {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    .label {
      @include extraSmallText();
      padding: 0 4px 0 0;
      line-height: 11px;
      font-weight: bold;
      color: var(--grey-dark);
    }
  }

  .card-footer {
    background-color: var(--grey-lighter);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--dark);

    div {
      flex-grow: 1;
      .label {
        @include extraSmallText();
        display: block;
        text-transform: uppercase;
        color: var(--grey-dark);
        font-weight: bold;
      }
      .caption-text {
        padding: 4px 0 0 0;
      }
      &.icon-footer {
        color: var(--primary);
        margin: 0 24px 0 8px;
        flex-grow: 0;
      }
    }
  }
}

.overview-stop {
  display: flex;
  align-items: top;
  flex-grow: 1;
  margin: 0;
  > .header {
    display: flex;
    flex: 1;
    justify-content: left;
    position: relative;
    .times {
      text-align: center;
      padding: 0 12px 0 0;
      span {
        display: block;
        &:first-child {
          font-weight: bold;
        }
      }
    }
    .details {
      flex: 1;
      position: relative;
      padding: 4px 0 4px 12px;
      min-height: 35px;
      &:before {
        content: "";
        display: block;
        width: 4px;
        border-radius: 2px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -3px;
        background: var(--primary);
      }
      span {
        display: block;
      }
      .meta {
        @include smallText();
        color: var(--grey-dark);
      }
      .stop-id {
        @include extraSmallText();
        font-weight: bold;
        color: var(--primary);
      }
    }
  }
  a {
    text-decoration: none;
  }
  .o4c.button {
    height: 32px;
    width: 32px;
    padding: 0;
    flex-grow: 0;
    i {
      font-size: 16px;
      flex-grow: 0;
    }
  }
}
