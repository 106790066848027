@import '~@o4c/css/mixins/typography';
@import '~@o4c/css/mixins/elevations';

.context-menu-container {
  // margin-top: 104px;
  .context-menu-trigger {
    position: fixed;
    z-index: 830;
    bottom: 90px;
    right: 16px;
    
    transition: right 0.3s ease-in-out, box-shadow 0.3s ease-in-out, bottom 0.3s ease-in-out,;
    transition-delay: 0.3s;
    i {
      transform: none;
      transition: transform 0.3s ease-in-out;
    }
  }
  .context-menu-slider {
    position: fixed;
    z-index: 820;
    height: 100vh;
    width: 100vw;
    bottom: -100vh;
    transition: bottom 0.3s ease-in-out;
    transition-delay: 0.3s;
  }
  .context-menu {
    @include elevation6();
    position: fixed;
    z-index: 820;
    left: 48px;
    right: 48px;
    overflow: hidden;
    border-radius: 8px;
    transition: bottom 0.3s ease-in-out;
    bottom: -100%;
    transition-delay: 0.3s;
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: -32px;
      left: 50%;
      margin-left: -32px;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      box-shadow: 0 0 0 99999px #fff;
    }
    .context-menu-header {
      @include smallText();
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      color: var(--grey-dark);
      background: var(--light);
      padding: 16px;
    }
    .context-menu-main {
      padding: 16px 24px 48px;
      button {
        margin: 0 0 8px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .context-menu-overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 45.83%);
    position: absolute;
    z-index: 810;
    height: 100vh;
    width: 100vw;
    bottom: 0;
    border: 0;
    opacity: 0;
    padding: 0;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0s;
  }
  &.visible {
    .context-menu-trigger {
      right: calc(50% - 28px);
      bottom: 24px;
      background: var(--white);
      i {
        transform: rotate(-225deg);
        color: var(--primary);
      }
    }
    .context-menu {
      bottom: 52px;
    }
    .context-menu-overlay {
      opacity: 1;
      transition-delay: 0.6s;
    }
    .context-menu-slider {
      bottom: 0;
      transition-delay: 0s;
    }
  }
}
